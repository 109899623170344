import React from 'react';
import Form from '../../components/forms/forms';
import {NavLink} from 'react-router-dom';
import Icon from '../../icons/';
import Input from '../../components/UI/Input/Input';
import {apiHost} from '../../common/config';
import Layout from '../../layouts/unauthorized';
import Button from "../../components/UI/Button/Button";
import FeedbackModalExt from "../../components/feedback-modal/feedback-modal-ext";

class Page extends Form {

    requestUrl = `${apiHost}/user/send/password/reset`;

    validationRules = {
        email: {require: true, title: 'Электронная почта', email: true}
    }

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            responseError: '',

            hashSent: false
        };
    }

    submitRequestCalllback = (res) => {
        this.setState({hashSent: true});
    }

    render() {

        return (
            <Layout>
                <div
                    className="flex min-h-screen flex-wrap md:flex-no-wrap md:flex-row flex-col-reverse main-page">
                    <div className="lg:w-2/5 md:w-1/2  p-6 flex flex-col mx-auto">
                        <div className="px-4 text-center flex flex-col justify-center items-center h-full">

                            <div className="text-center">
                                <h1 className="black">Восстановление пароля</h1>
                            </div>
                            {
                                this.state.hashSent ? this.renderIfHasWasSent() : this.renderPasswordResetForm()
                            }
                        </div>
                    </div>
                    <div className="lg:w-3/5 md:w-1/2 background-main"/>
                </div>
            </Layout>
        );
    }

    renderPasswordResetForm() {
        return (
            <>
                <div className="text-center">
                    <p className={'text-essilor-gray'}>Для восстановления доступа укажите свой E-mail</p>
                </div>

                <div className="text-center text-red-500 h-10 mt-6">{this.state.responseError}</div>

                <div className="w-4/5 text-left mx-auto">
                    <form onSubmit={this.submitHandler.bind(this)} method="post">
                        <Input label="Электронная почта" placeholder="Введите ваш e-mail" name="email"
                               errors={this.state.errors}
                               onChange={this.removeError}/>

                        <Button type="submit" className="btn-blue uppercase my-8 block w-full">
                            Получить пароль
                        </Button>
                    </form>

                    <p className="text-center flex justify-between">
                        <small className="opacity-50 hover:opacity-100">
                            <NavLink to="/signin">Войти в личный кабинет</NavLink>
                        </small>
                        <small className="opacity-50 hover:opacity-100">
                            <NavLink to="/signup">Регистрация</NavLink>
                        </small>
                    </p>

                    <NavLink to="/">
                        <Icon name="logo" fill="#3A84D0" className="mx-auto max-w-8/9 mt-10"/>
                    </NavLink>

                    <FeedbackModalExt/>

                </div>
            </>
        )
    }

    renderIfHasWasSent() {
        return (
            <>
                <div className="text-center">
                    <p>На указанный E-mail было отправлено письмо с ссылкой для восстановления пароля.
                        Если письмо не пришло, пожалуйста, проверьте папку "Спам".</p>
                </div>

                <div className="w-4/5 text-left mx-auto mt-10">
                    <p className="text-center flex justify-between">
                        <small className="opacity-50 hover:opacity-100">
                            <NavLink to="/signin">Войти в личный кабинет</NavLink>
                        </small>
                        <small className="opacity-50 hover:opacity-100">
                            <NavLink to="/signup">Регистрация</NavLink>
                        </small>
                    </p>

                    <NavLink to="/">
                        <Icon name="logo" fill="#fff" className="mx-auto max-w-8/9 my-10"/>
                    </NavLink>
                </div>
            </>
        )
    }
}


export default Page;
