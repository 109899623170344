import React from 'react'
import  './InputWithButton.scss';
import InputMask from 'react-input-mask';


const InputWithButton = props => {
    let propsNew = {...props};
    propsNew.id = props.name;

    let hasError = false;

    if (props.hasOwnProperty('errors') && props.errors.hasOwnProperty(props.name) && props.errors[props.name]) {
        propsNew.className += ' invalid';
        hasError = true;
    }

    return (
        <div className={`InputWithButton ${propsNew.className}`}>
            <label htmlFor={propsNew.id}>
                {propsNew.label}
                {hasError ? (<i className="icon-alert-triangle error-icon"></i>) : ''}
            </label>
            <div className="md:flex md:justify-between w-full">
                <InputMask className="w-full md:w-8/12" {...propsNew} />
                <button onClick={propsNew.handleButton} className="btn btn-narrow btn-blue w-full md:w-4/12">
                    {propsNew.buttonName}
                </button>
            </div>
        </div>
    )

};

export default InputWithButton
