import React from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

import Icon from '../icons/';
import Menu from '../components/menu/menu';
import Notifications from '../components/notifications/notifications';
import store from '../store/store';
import './authorized.scss';
import Alert from './alert';
import axios from "axios";
import {apiHost} from "../common/config";
import qs from 'qs';
import FormErrors from "./form-errors";

class Layout extends React.Component {

    constructor(props) {
        super(props);
        console.log(props);
    }

    handleMenuIsOpen = (event) => {
        event.preventDefault();

        store.dispatch({
            type: 'setMenuIsOpen'
        });
    }


    render() {
        if (!this.props.isLogged) {
            return <Redirect to='/logout'/>;
        }

        return (
            <section className="home min-h-screen flex flex-col">

                <Alert/>
                <FormErrors/>

                <header className="flex items-center justify-between h-16">
                    <div className="flex w-1/4 justify-between items-center pl-6 py-2">
                        <Icon name="logo-short" width="75%" fill="white" className="hidden md:block"/>
                        <a href="#" onClick={this.handleMenuIsOpen}>
                            <Icon name="menu" width="35" />
                        </a>
                    </div>
                    <div className="flex pr-6 items-center header-menu">

                        <NavLink to="/logout" className="px-3">
                            <i className="icon-logout text-2xl md:text-lg"/>
                        </NavLink>
                        <Notifications/>
                        <NavLink to="/lk/settings" className="px-3">
                            <i className="icon-settings text-2xl md:text-lg"/>
                        </NavLink>
                        <span className="text-white px-3 hidden md:inline">
                            Здравствуйте, &nbsp;{this.props.userName}
                        </span>
                        {
                            this.props.userPhoto &&
                            <img src={this.props.userPhoto} className="w-10 h-10 object-cover rounded-md"/>
                        }
                    </div>
                </header>
                <div className="flex flex-1 mt-16">
                    <Menu/>
                    <div className="layout-content items-start relative" ref={this.props.layoutContentRef}>
                        {
                            this.props.shouldConfirmEmail && (
                                <div className="alert-message-top text-2xl md:text-lg">
                                    Пожалуйста, обратите внимание, что вам необходимо подтвердить ваш адрес
                                    электронной почты в течение недели с момента регистрации.
                                </div>
                            )
                        }
                        {
                            this.props.children
                        }
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.user
    }
}

export default connect(mapStateToProps)(Layout);
