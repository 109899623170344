import React from 'react';
import Form from '../../components/forms/forms';
import {NavLink} from 'react-router-dom';
import Icon from '../../icons/';
import {apiHost} from '../../common/config';
import queryString from 'query-string';
import axios from 'axios';
import FeedbackModalExt from "../../components/feedback-modal/feedback-modal-ext";

class Page extends Form {

    requestUrl = `${apiHost}/user/verifyemail`;

    constructor(props) {
        super(props);

        this.state = {
            responseError: '',
            responseSuccess: false,
            responseSuccessMessage: '',
        };
    }

    componentDidMount() {
        const {hash} = queryString.parse(this.props.location.search);
        const {email} = queryString.parse(this.props.location.search)

        if (hash && email) {
            axios.get(
                `${apiHost}/user/verifyemail`, {
                    params: {
                        email: email,
                        hash: hash,
                    }
                }
            ).then(res => {
                this.setState({responseSuccess: true, responseSuccessMessage: res.data.message});
            })
                .catch(error => {
                    this.setState({responseError: error.response.data.error.message});
                });
        } else {
            this.setState({responseError: 'Ссылка не действительна.'});
        }
    }

    render() {
        return (
            <>
                <div
                    className="flex min-h-screen flex-wrap md:flex-no-wrap md:flex-row flex-col-reverse main-page">
                    <div className="lg:w-2/5 md:w-1/2  p-6 flex flex-col mx-auto">
                        <div className="px-4 text-center flex flex-col justify-center  items-center h-full">

                            <div className="text-center text-red-500 h-10 mt-6">{this.state.responseError}</div>
                            <div className="text-center h-10 mt-6">{this.state.responseSuccessMessage}</div>

                            <div className="w-4/5 text-left mx-auto">
                                <NavLink to="/">
                                    <Icon name="logo" fill="#3A84D0" className="mx-auto max-w-8/9 mt-10"/>
                                </NavLink>

                                <FeedbackModalExt/>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-3/5 md:w-1/2 background-main"/>
                </div>
            </>
        );
    }
}


export default Page;
