import React from 'react';
import {apiHost} from '../../../common/config';
import Layout from '../../../layouts/authorized';
import Input from '../../../components/UI/Input/Input';
import axios from 'axios';
import store from "../../../store/store";
import './user.scss';
import Select from "../../../components/UI/Select/Select";
import CustomTable from '../../../components/table/table.2.0';
import DatePickerRange from "../../../components/UI/DatePicker/DatePickerRange";
import CustomTableOwner from "../../../components/table-owner/table.2.0";
import InputWithButton from "../../../components/UI/InputWithButton/InputWithButton";

class Page extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            welcomeLink: '',
            headers: [
                {code: 'personalName', caption: 'ФИО', type: 'text', link: true},
                {code: 'personalRegisterDate', caption: 'Дата регистрации', type: 'text'},
                {code: 'address', caption: 'Адрес', type: 'text'},
                {code: 'group', caption: 'Роль', type: 'text'},
                {code: 'level', caption: 'Уровень', type: 'text'},
                {code: 'salePoints', caption: 'Баллы продажи', type: 'number'},
                {code: 'educationPoints', caption: 'Баллы обучение', type: 'number', useCaption: true},
                {code: 'points', caption: 'Итого баллов', type: 'number'},
            ],
            tableData: [],
            addresses: [
                {value: '', label: 'Все оптики'},
            ],
            groups: [
                {value: '', label: 'Все роли'},
                {value: 'DOCTOR_USERS', label: 'Врач'},
                {value: 'CONSULTANT_USERS', label: 'Консультант'}
            ],
            levels: [
                {value: '', label: 'Все уровни'},
                {value: '1', label: 'Специалист'},
                {value: '2', label: 'Профессионал'},
                {value: '3', label: 'Эксперт'}
            ],
            filter: {
                group: '',
                address: '',
                levels: '',
                limit: 10,
                page: 1,
            },
            searching: true,
            allRecords: 0,
        }
    }

    componentDidMount()
    {
        const {filter} = this.state;
        axios.get(
            `${apiHost}/user/get/welcomeLink`,
            {withCredentials: true}
        )
        .then(res => {
            this.setState(res.data);
        })
        .catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

        this.getTableData(filter);
        this.getAddresses();
    }

    getTableData(filter) {
        axios.get(
            `${apiHost}/activity/get/ecp/users/stat`,
            {
                withCredentials: true,
                params: {
                    filter: JSON.stringify(filter)
                }
            }
        ).then(res => {
            this.setState({
                tableData: res.data.data,
                allRecords: res.data.allRecords
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {
            this.setState({
                searching: false
            });
        });

    }

    getAddresses() {
        axios.get(
            `${apiHost}/entity/get/addresses/forOwner`,
            {withCredentials: true,}
        ).then(res => {
            const { addresses } = this.state;
            this.setState({
                addresses: [...addresses, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    handleChangeGroup(selectedOption) {
        const {filter} = this.state;
        filter.group = selectedOption.value;
        this.setState({filter});
    }

    handleChangeAddress(selectedOption) {
        const {filter} = this.state;
        filter.address = selectedOption.value;
        this.setState({filter});
    }

    handleChangeLevel(selectedOption) {
        const {filter} = this.state;
        filter.level = selectedOption.value;
        this.setState({filter});
    }

    handleChangeDatePickerRange = (startDate, endDate) => {
        const {filter} = this.state;
        filter.dateStart = startDate;
        filter.dateEnd = endDate;
        this.setState({filter});
    }

    handleChangePage = (page) => {
        const {filter} = this.state
        filter.page = page;
        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    handleSearch = () => {
        const {filter} = this.state;
        filter.page = 1;

        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    copyLink = () => {
        console.log('Copy Link');

        navigator.clipboard.writeText(this.state.welcomeLink);

        store.dispatch({
            type: 'addResponseStatus',
            status: {
                code: 200,
                message: 'Ссылка скопирована!'
            }
        });
    }

    render() {

        return (
            <Layout>
                <h2 className="page-title">Мои сотрудники</h2>
                <div className="w-full">
                    <div className="Input undefined">
                        <InputWithButton
                            label="Ссылка для регистрации новых участников (консультанты/врачи)"
                            name="welcomeLink"
                            disabled
                            value={this.state.welcomeLink}
                            buttonName="Скопировать ссылку"
                            handleButton={this.copyLink}
                        />
                        <p className="text-xs">Скопируйте эту ссылку и отправьте вашим сотрудникам,
                            чтобы они могли начать присоединиться к вашей команде.
                        </p>
                    </div>
                </div>
                <div className="horizontal-line mt-5 mb-5"></div>

                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="address"
                            label="Выберите адрес"
                            placeholder="Все оптики"
                            options={this.state.addresses}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeAddress(selectedOption)}
                        />
                    </div>

                    <div className="md:w-6/12 md:ml-5">
                        <DatePickerRange onChange={this.handleChangeDatePickerRange} />
                    </div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4 mb-5">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="levels"
                            label="Выберите уровень"
                            placeholder="Все"
                            options={this.state.levels}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeLevel(selectedOption)}
                        />
                    </div>

                    <div className="md:w-6/12 md:ml-5">
                        <Select
                            name="groups"
                            label="Выберите роль"
                            placeholder="Все"
                            options={this.state.groups}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeGroup(selectedOption)}
                        />
                    </div>
                </div>
                <div className="w-full md:flex md:justify-between md:w-3/4 mb-10 mt-3">
                    <div className="md:w-6/12 md:mr-5">
                        <div className="w-full md:flex md:justify-between">
                            <button disabled={this.state.searching ? "disabled" : ""}
                                    className="btn btn-narrow btn-blue w-full md:w-6/12 md:mr-5"
                                    onClick={this.handleSearch}>Найти</button>

                        </div>
                    </div>

                    <div className="md:w-6/12 md:ml-5"></div>
                </div>

                {  this.state.tableData.length > 0 &&
                <CustomTable
                    headers={this.state.headers}
                    tableData={this.state.tableData}
                    showSpin={this.state.searching}
                    allRecords={this.state.allRecords}
                    perPage={this.state.filter.limit}
                    currentPage={this.state.filter.page}
                    onChangePage={this.handleChangePage}
                    exportLink={`${apiHost}/export/users?filter=${JSON.stringify(this.state.filter)}`}
                />
                }

            </Layout>
        );
    }
}



export default Page;
