import React from 'react';
import {apiHost} from '../../../common/config';
import {connect} from 'react-redux';
import Layout from '../../../layouts/authorized';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import Form from '../../../components/forms/forms';
import './settings.scss';
import axios from 'axios';
import store from "../../../store/store";
import InputFile from "../../../components/UI/InputFile/InputFile";

class Page extends Form {

    requestUrl = `${apiHost}/user/update`;

    validationRules = {
        name: {require: true, title: 'Ваше ФИО'},
        phone: {require: true, title: 'Контактный телефон', minLength: 11},
        password: {title: 'Пароль', equalTo: 'password_copy', minLength: 8},
        password_copy: {title: 'Повторите пароль ещё раз'},
    };

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            responseError: '',

            userData: {
                ROLE: {
                    code: '',
                    label: ''
                },
                EMAIL: '',
                NAME: '',
                PERSONAL_PHONE: '',
                LEGAL_ADDRESSES: []
            }
        };
    }

    componentDidMount() {
        axios.get(
            `${apiHost}/user/get/info`,
            {withCredentials: true}
        )
            .then(res => {
                this.setState({userData: res.data});
            })
            .catch(error => {
                store.dispatch({
                    type: 'addResponseStatus',
                    status: error.response.data.error
                })
            });
    }

    onPhoneChange(input) {
        const value = input.value;
        input.value = value.replace(/[^\d+\-\(\)]*/gi, '');
    }

    submitRequestCalllback = (res) => {
        this.setState({userData: res.data.data});

        store.dispatch({
            type: 'refreshUserInfo',
            userName: res.data.data.NAME,
            userPhoto: res.data.data.USER_PHOTO,
        });
    };

    render() {

        return (
            <Layout>

                <h2 className="page-title">Мои данные</h2>

                <form onSubmit={this.submitHandler.bind(this)} method="post" className="w-full">
                    <h3 className="mt-0 mb-10 black">Редактирование информации</h3>
                    <div className="w-full max-w-sm md:ml-20">
                        <div className="Input undefined">
                            {
                                this.state.userData.LEGAL_ADDRESSES.map((item, index) => (
                                    <>
                                        <label>Я работаю в:</label>
                                        <p key={`ula-${index}`} className="mt-2">{item.label}</p>
                                    </>
                                ))
                            }
                        </div>

                        <Input label="Уровень доступа" placeholder="Уровень доступа" name="role" disabled
                               value={this.state.userData.ROLE.label}
                               onChange={this.removeError}
                               errors={this.state.errors}/>

                        <Input label="Ваше ФИО" placeholder="Введите ФИО" name="name"
                               defaultValue={this.state.userData.NAME}
                               onChange={this.removeError}
                               errors={this.state.errors}/>
                        <Input label="Электронная почта" disabled value={this.state.userData.EMAIL}/>
                        <Input label="Контактный телефон" placeholder="Номер телефона"
                               defaultValue={this.state.userData.PERSONAL_PHONE}
                               onChange={this.removeError}
                               name="phone"
                               onInput={event => this.onPhoneChange(event.target)}
                               errors={this.state.errors}/>
                    </div>
                    <div className="horizontal-line mt-10"/>
                    <h3 className="mt-10 mb-10 black">Изменить пароль доступа</h3>
                    <div className="w-full max-w-sm md:ml-20">
                        <Input label="Новый пароль" placeholder="Придумайте новый пароль" name="password"
                               onChange={this.removeError}
                               errors={this.state.errors}/>
                        <Input label="Повторите пароль ещё раз" placeholder="Введите новый пароль" name="password_copy"
                               onChange={this.removeError}
                               errors={this.state.errors}/>

                    </div>
                    <div className="horizontal-line mt-10"/>
                    <h3 className="mt-10 mb-10 black">
                        {this.state.userData.USER_PHOTO ? `Изменить` : `Добавить`} фото профиля
                    </h3>
                    <div className="w-full max-w-sm md:ml-20">
                        {
                            this.state.userData.USER_PHOTO &&
                                <img className="rounded-lg w-full mb-6" src={this.state.userData.USER_PHOTO}/>
                        }

                        <InputFile label="Выберите изображениие"
                                   name="image"
                                   className="w-full"
                                   accept="image/jpeg,image/png,image/gif"
                        />

                        <Button type="submit" className="btn btn-blue uppercase my-8 block w-full">
                            Сохранить изменения
                        </Button>
                    </div>
                </form>

            </Layout>
        );
    }
}

export default Page;
